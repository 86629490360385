import React from 'react'


function PageFooter(props){
	return(
		<div>
			<p style={{textAlign:"right", color:"grey"}}>Site fait par Damien Armillon (alias Edsheebran) pour le Toucan Déchainé</p>
		</div>
	)
}

export default PageFooter