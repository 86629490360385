import React from 'react';
import {Image, Button} from 'semantic-ui-react'
import LogoutButton from '../utils/LogoutButton'
import logo from '../image/logo.png'
import env from './../.env'



const PageHeader = (props) => {
    console.table(env)
    return (
        [   
            // Si le format est celui d'un Pc
            <div className="desktopItem" style={{textAlign:"center"}}> {console.log(props.isLogged)}
                <Image src={logo} alt="Logo" href="/" style={{ width:'40%'}} />
				{props.isLogged ? <LogoutButton style={{position:"absolute",top:'2em' ,right:"2em"}}/>:
						<Button icon="male" content="Espace admin" style={{position:"absolute",top:'2em' ,right:"2em"}} onClick={() =>window.location=`${env.backURL}/oauth/login`}/>
                }
                
                <a href={"https://www.facebook.com/LeToucanDechaine/"}> <Button icon="facebook" content="Facebook" style={{position:"absolute",top:'2em' ,left:"2em", color: "white", backgroundColor:"#3b5998"}}/></a>
                <a href={"https://instagram.com/le.toucan.dechaine"}><Button icon="instagram" content="Insta" style={{position:"absolute",top:'5em' ,left:"2em", color: "white", backgroundColor:"#c13584"}}/></a>
                
            </div>,
            // Si le format est celui d'un téléphone
            <div className="phoneItem" style={{textAlign:"center"}}>
                <Image src={logo} alt="Logo" href="/" style={{ margin: "2em"}} />
                <br/>
                <div style={{padding:"0.7em"}}>
                <a href={"https://www.facebook.com/LeToucanDechaine/"}> <Button icon="facebook" style={{position:"absolute", left:"3em", color: "white", backgroundColor:"#3b5998"}}/></a>
                <a href={"https://instagram.com/le.toucan.dechaine"}><Button icon="instagram" style={{position:"absolute", left:"6em", backgroundColor:"#c13584"}}/></a>
                {props.isLogged ? <LogoutButton/>:
						<Button icon="male" content="Espace admin" style={{position:"absolute", right:"3em"}} onClick={() =>window.location=`${env.backURL}/oauth/login`}/>
				}
                </div>
            </div>
        ]
    )
}

export default PageHeader;
